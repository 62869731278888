import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBrOuymWofFzFlxOi-iyCflQhKo1hb50v8",
  authDomain: "read-robin-2e150.firebaseapp.com",
  projectId: "read-robin-2e150",
  storageBucket: "read-robin-2e150.appspot.com",
  messagingSenderId: "31059740280",
  appId: "1:31059740280:web:20746a8444f69ee1364f8d",
  measurementId: "G-DD6QBT90F7",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };
